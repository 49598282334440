var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "j-modal",
    {
      attrs: {
        title: _vm.title,
        width: _vm.width,
        visible: _vm.visible,
        switchFullscreen: "",
        okButtonProps: { class: { "jee-hidden": _vm.disableSubmit } },
        cancelText: "关闭"
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
    },
    [
      _c("base-major-form", {
        ref: "realForm",
        attrs: {
          schoolIdCode: _vm.schoolIdCode,
          departmentId: _vm.departmentId,
          disabled: _vm.disableSubmit
        },
        on: { ok: _vm.submitCallback }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }