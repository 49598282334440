var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticStyle: { top: "5%", height: "85%", "overflow-y": "hidden" },
      attrs: {
        title: _vm.title,
        width: 800,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
        cancelText: "关闭",
        wrapClassName: "ant-modal-cust-warp"
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "form",
                attrs: { model: _vm.model, rules: _vm.validatorRules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                {
                  attrs: { label: "角色编码", required: "", prop: "roleCode" }
                },
                [
                  _c("a-input", {
                    attrs: {
                      disabled: _vm.roleDisabled,
                      placeholder: "请输入角色编码"
                    },
                    model: {
                      value: _vm.model.roleCode,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "roleCode", $$v)
                      },
                      expression: "model.roleCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: { label: "角色名称", required: "", prop: "roleName" }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入角色名称" },
                    model: {
                      value: _vm.model.roleName,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "roleName", $$v)
                      },
                      expression: "model.roleName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "描述", prop: "description" } },
                [
                  _c("a-textarea", {
                    attrs: { rows: 5, placeholder: "请输入角色描述" },
                    model: {
                      value: _vm.model.description,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "description", $$v)
                      },
                      expression: "model.description"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }