<template>
  <a-card :bordered="false" style="min-height:500px;">
    <!-- {{model}} -->
    <!-- 查询区域 -->
    <!-- <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="院系编码">
              <a-input placeholder="请输入院系编码" v-model="queryParam.code"></a-input>
            </a-form-item>
          </a-col>
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="院系名称">
              <a-input placeholder="请输入院系名称" v-model="queryParam.name"></a-input>
            </a-form-item>
          </a-col>
          <template v-if="toggleSearchStatus">
            <a-col :xl="6" :lg="7" :md="8" :sm="24">
              <a-form-item label="状态">
                <j-dict-select-tag placeholder="请选择状态" v-model="queryParam.isEnable" dictCode="status"/>
              </a-form-item>
            </a-col>
          </template>
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
              <a @click="handleToggleSearch" style="margin-left: 8px">
                {{ toggleSearchStatus ? '收起' : '展开' }}
                <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div> -->
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <!-- <div class="table-operator">
      <a-button @click="handleAdd1" type="primary" icon="plus">新增专业</a-button>
      <a-button @click="handleAdd2" type="primary" icon="plus">新增班级</a-button>
      <a-button type="primary" icon="download" @click="handleExportXls('院系表')">导出</a-button>
      <a-upload
        name="file"
        :showUploadList="false"
        :multiple="false"
        :headers="tokenHeader"
        :action="importExcelUrl"
        @change="handleImportExcel"
      >
        <a-button type="primary" icon="import">导入</a-button>
      </a-upload>
      
    </div> -->
      <a-row :span="24" >
        <a-form-model-item v-if="showSearchSchool" class="selectBox"  label="学校名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            v-model:value="model.schoolIdCode"
            default-active-first-option="false"
            :filter-option="false"
            :not-found-content="null"
            :options="schoolData"
            :show-arrow="false"
            show-search
            show-placeholder
            style="width: 300px"
            @search="handleSearch"
            @change="roomChange"
            placeholder="请输入学校名称进行搜索"
          >
          </a-select>
        </a-form-model-item>
        <div v-if="!showSearchSchool" class="schoolName">{{model.name}}<span class="updateSchool" @click="updateSchool">修改</span></div>
     
      </a-row>
      <a-row :gutter="128" v-if="!showSearchSchool">
      <a-col class="gutter-row list" :span="8" >
        <div class="gutter-box-title">
            <p>院系</p>
            <p></p>
            <div class="icons-list">
              <a-icon type="redo"  class="icon" @click="loade(1)"  style="font-size:18px;margin-right:5px;"/>
              <a-icon type="plus-circle"  @click="handleAdd1" class="icon" style="font-size:18px;margin-right:5px;"/>
            </div>
        </div>
        <div class="gutter-box">
          <ul class="ant-menu ant-menu-inline ant-menu-sub">
            <!--   -->
            <li v-for="item in departmentList" :class="item.id==model.departmentId ? 'ant-menu-item ant-menu-item-selected' : 'ant-menu-item'" :key="item.id" @click="chooseDepart(item)"  >
              <a href="javascript:;">
                <span>{{item.name}}</span>
              </a>
              <p></p>
              <p style="z-index:99">
                   <a-icon type="edit"  class="icon" @click.stop="handleAdd1(true,item)"  style="font-size:18px;margin-right:5px;"/>
                   <a-icon type="delete"  class="icon" @click.stop="removeDepartment(1,item)"  style="font-size:18px;margin-right:5px;"/>
              </p>
            </li>
          </ul>
        </div>
      </a-col>
      <a-col class="gutter-row  list" :span="8">
        <div class="gutter-box-title">
            <p>专业</p>
            <p></p>
            <div class="icons-list">
              <a-icon type="redo"  class="icon" @click="loade(2)"  style="font-size:18px;margin-right:5px;"/>
              <a-icon type="plus-circle"  @click="handleAdd2" class="icon" style="font-size:18px;margin-right:5px;"/>
            </div>
        </div>
        <div class="gutter-box">
          <ul class="ant-menu ant-menu-inline ant-menu-sub">
            <li v-for="item in majorList" :key="item.id"  @click="chooseMajor(item)" :class="item.id==model.majorId ? 'ant-menu-item ant-menu-item-selected' : 'ant-menu-item'" >
              <a href="javascript:;">
                <span>{{item.name}}</span>
              </a>
              <p></p>
              <p style="z-index:99">
                   <a-icon type="edit"  class="icon" @click.stop="handleAdd2(true,item)"  style="font-size:18px;margin-right:5px;"/>
                   <a-icon type="delete"  class="icon" @click.stop="removeDepartment(2,item)"  style="font-size:18px;margin-right:5px;"/>
              </p>
            </li>
          </ul>
        </div>
      </a-col>
      <a-col class="gutter-row  list" :span="8">
        <div class="gutter-box-title">
            <p>班级</p>
            <p></p>
            <div class="icons-list">
              <a-icon type="redo"  class="icon" @click="loade(3)"  style="font-size:18px;margin-right:5px;"/>
              <a-icon type="plus-circle"  @click="handleAdd3" class="icon" style="font-size:18px;margin-right:5px;"/>
            </div>
        </div>
        <div class="gutter-box">
          <ul class="ant-menu ant-menu-inline ant-menu-sub">
            <li v-for="item in classList" :key="item.id"   :class="item.id==model.classId ? 'ant-menu-item ant-menu-item-selected' : 'ant-menu-item'" >
              <a href="javascript:;" class="departMentName">
                <span>{{item.name}}</span>
              </a>
              <p></p>
              <p style="z-index:99">
                   <a-icon type="edit"  class="icon" @click.stop="handleAdd3(true,item)"  style="font-size:18px;margin-right:5px;"/>
                   <a-icon type="delete"  class="icon" @click.stop="removeDepartment(3,item)"  style="font-size:18px;margin-right:5px;"/>
              </p>
            </li>
          </ul>
        </div>
      </a-col>
    </a-row>
    <a-row v-if="showSearchSchool" >
      <a-col class="gutter-row searchSchoolTips" :span="24">
        <div class="ant-empty-image"><svg width="128" height="128" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fillRule="evenodd"><ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse><g fillRule="nonzero" stroke="#D9D9D9"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path></g></g></svg></div>
      请先选择学校</a-col>
    </a-row>
    <base-department-modal :schoolIdCode="model.schoolIdCode" ref="modalForm" @ok="baseDepartmentAdd"></base-department-modal>
    <base-major-modal :departmentId="model.departmentId" :schoolIdCode="model.schoolIdCode" ref="modalFormMajor" @ok="baseMajorAdd"></base-major-modal>
    <tb-class-modal :majorId="model.majorId" :departmentId="model.departmentId" :schoolIdCode="model.schoolIdCode" ref="modalFormClass" @ok="baseClassAdd"></tb-class-modal>
  </a-card>
</template>

<script>
import '@/assets/less/TableExpand.less'
import { mixinDevice } from '@/utils/mixin'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import BaseDepartmentModal from './modules/BaseDepartmentModal'
import BaseMajorModal from './modules/BaseMajorModal'
import TbClassModal from './modules/TbClassModal'
import { filterMultiDictText } from '@/components/dict/JDictSelectUtil'
import { httpAction, getAction,postAction,deleteAction} from '@/api/manage'
export default {
  name: 'BaseDepartmentList',
  mixins: [JeecgListMixin, mixinDevice],
  components: {
    BaseDepartmentModal,
    BaseMajorModal,
    TbClassModal,
  },

  data() {
    return {
      majorList:[],
      classList:[],
      departmentList:[],
      showSearchSchool:true,
      model: { "schoolIdCode": "", "name": "", "departmentId": "", "majorId": "","classId":"" },
      schoolData: [],
      description: '院系表管理页面',
      // 表头
      columns: [
        /* {
            title: '#',
            dataIndex: '',
            key:'rowIndex',
            width:60,
            align:"center",
            customRender:function (t,r,index) {
              return parseInt(index)+1;
            }
          }, */
        {
          title: '院系编码',
          align: 'center',
          dataIndex: 'code',
        },
        {
          title: '院系名称',
          align: 'center',
          dataIndex: 'name',
        },
        {
          title: '创建时间',
          align: 'center',
          sorter: true,
          dataIndex: 'createTime',
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'isEnable_dictText',
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          width: 147,
          scopedSlots: { customRender: 'action' },
        },
      ],
      url: {
        list: '/ylpp/baseDepartment/list',
        delete: '/ylpp/baseDepartment/delete',
        deleteBatch: '/ylpp/baseDepartment/deleteBatch',
        exportXlsUrl: '/ylpp/baseDepartment/exportXls',
        importExcelUrl: 'ylpp/baseDepartment/importExcel',
        querySchool: '/api/university/list',
        baseDepartmentAll: 'ylpp/baseDepartment/list',
        baseMajorAll: 'ylpp/baseMajor/list',
        baseClassAll: 'ylpp/tbClass/list',
        baseDepartmentAdd: 'ylpp/baseDepartment/add',
        deleteTBClass: 'ylpp/tbClass/delete',
        deleteBaseMajor: 'ylpp/baseMajor/delete',
        deleteBaseDepartment: 'ylpp/baseDepartment/delete',
      },
      dictOptions: {},
      superFieldList: [],
    }
  },
  created() {
    this.getSuperFieldList()
  },
  computed: {
    importExcelUrl: function () {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    },
  },
  methods: {
    initDictConfig() {},
    getSuperFieldList() {
      let fieldList = []
      fieldList.push({ type: 'string', value: 'code', text: '院系编码', dictCode: '' })
      fieldList.push({ type: 'string', value: 'name', text: '院系名称', dictCode: '' })
      fieldList.push({ type: 'datetime', value: 'createTime', text: '创建时间' })
      fieldList.push({ type: 'int', value: 'isEnable', text: '状态', dictCode: 'status' })
      this.superFieldList = fieldList
    },
    handleAdd2: function (falg,record) {
      if(!falg){
        this.$refs.modalFormMajor.title = '新增专业'
        this.$refs.modalFormMajor.add()
      }else{
        this.$refs.modalFormMajor.title = '修改专业'
        this.$refs.modalFormMajor.edit(record)
      }
      this.$refs.modalFormMajor.disableSubmit = false
    },
    handleAdd1: function (falg,record) {
      if(!falg){
        this.$refs.modalForm.title = '新增院系'
        this.$refs.modalForm.add()
      }else{
        this.$refs.modalForm.title = '修改院系'
        this.$refs.modalForm.edit(record)
      }
      this.$refs.modalForm.disableSubmit = false
    },
    handleAdd3: function (falg,record) {
      if(!falg){
        this.$refs.modalFormClass.title = '新增班级'
        this.$refs.modalFormClass.add()
      }else{
        this.$refs.modalFormClass.title = '修改班级'
        this.$refs.modalFormClass.edit(record)
      }
      this.$refs.modalFormClass.disableSubmit = false
    }, //下拉列表
    async handleSearch(val) {
      this.schoolData = []
      let params = { name: val, pageNo: 1, pageSize: 100 }
      getAction(this.url.querySchool, params).then((res) => {
        if (res.success) {
          res.result.records.forEach((item) => {
            this.schoolData.push({
              //循环，把值装进去
              value: item.code,
              label: item.name,
            })
          })
        }
      })
    },
    roomChange(val) {
      let that = this;
      this.schoolData.forEach((item) => {
        if (val == item.value) {
          this.model.name = item.label
          this.$confirm({
            title: '提示',
            class: 'deltip',
            width: '400px',
            content:`确认选择${this.model.name }?`,
            okText: '确认',
            cancelText: '取消',
            type: 'warning',
            icon: () => {
              return <a-icon type="exclamation-circle" />
            },
            onOk() {
              that.showSearchSchool=false;
              that.baseDepartmentAll();
            },
            onCancel() {
            },
          })
        }
      })
    },baseDepartmentAll(){
      this.departmentList = []
      let params = {
        code:"" ,
        id:'',
        name:'',
        pageNo:'1',
        pageSize:'100',
        schoolIdCode:this.model.schoolIdCode,		
        tenantId:'',	
      }
      getAction(this.url.baseDepartmentAll, params).then((res) => {
        if (res.success) {
           this.departmentList=res.result.records
        }
      })

    },baseClassAll(){
      this.classList = []
      let params = {
        code:"" ,
        id:'',
        name:'',
        pageNo:'1',
        pageSize:'100',
        departmentId:this.model.departmentId,
        majorId:this.model.majorId,
        schoolIdCode:this.model.schoolIdCode,		
        tenantId:'',	
      }
      getAction(this.url.baseClassAll, params).then((res) => {
        if (res.success) {
           this.classList=res.result.records
        }
      })

    },baseMajorAll(){
      this.majorList = [];
      let params = {
        code:"" ,
        id:'',
        name:'',
        pageNo:'1',
        pageSize:'100',
        schoolIdCode:this.model.schoolIdCode,
        departmentId:this.model.departmentId,

        tenantId:'',	
      }
      getAction(this.url.baseMajorAll, params).then((res) => {
        if (res.success) {
           this.majorList=res.result.records;
        }
      })

    },baseDepartmentAdd(){
      this.baseDepartmentAll()
    },baseMajorAdd(){
      this.baseMajorAll()
    },baseClassAdd(){
      this.baseClassAll()
    },updateSchool(){
      let that = this;
      this.$confirm({
            title: '提示',
            class: 'deltip',
            width: '400px',
            content:`是否更换学校?`,
            okText: '确认',
            cancelText: '取消',
            type: 'warning',
            icon: () => {
              return <a-icon type="exclamation-circle" />
            },
            onOk() {
              that.model ={
              schoolIdCode: '',
              name:'',
              departmentId:'',
              majorId:'',
            }
              that.showSearchSchool=true;
            },
            onCancel() {
            },
          })
    },chooseDepart(row){
        this.model.departmentId=row.id;
        this.model.majorId="";
        this.model.classId="";
        this.baseMajorAll()
    },chooseMajor(row){
      this.model.majorId=row.id;
      this.model.classId="";
      this.baseClassAll()
    },chooseClass(row){
        this.model.classId=row.id;
    },loade(flag){
      if(flag==1){
        this.model.departmentId='';
        this.baseDepartmentAll();
        this.majorList=[];
        this.classList=[];
        this.model.majorId='';
      }else if(flag==2){
        this.model.majorId='';
        this.model.classId='';
        this.classList=[];
        this.baseMajorAll();
      }else if(flag==3){
        this.model.classId='';
        this.baseClassAll();
      }

    },editDepartment(flag,record){
    },removeDepartment(flag,record){
      let that = this;
      let url =flag==1?this.url.deleteBaseDepartment:flag==2?this.url.deleteBaseMajor:this.url.deleteTBClass;
this.$confirm({
          title: "确认删除",
          content: "是否删除选中数据?",
          onOk: function () {
            that.loading = true;
            deleteAction(url, { id: record.id }).then((res) => {
              if (res.success) {
                that.loade(flag);
              } else {
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.loading = false;
            });
          }
        });
    }
  },
}
</script>
<style scoped>
@import '~@assets/less/common.less';
.selectBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .ant-select-selection__placeholder{
  display: block;
}
.searchSchoolTips{
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
}
.schoolName{
      font-size: 36px;
    text-align: center;
    color: green;
    margin: 40px;
}
.updateSchool{
  font-size: 12px;
  cursor: pointer;
}
::v-deep .icon:hover{
  color:#1890FF;
}
.gutter-box-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1890FF;
    padding: 0px 0px 0px 10px;
    color: #fff;
    height: 40px;
    line-height: 40px;
}
ul li{
  list-style: none;
}
ul li:hover{
  color: #1890FF;
}
.ant-menu-item-selected ::before{
    transform: scaleY(1);
    opacity: 1;
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.list .gutter-box{
  border:1px solid #1890FF;
} 
::v-deep .ant-menu-item{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height:40px !important;
  white-space: pre-wrap;
  height:100% !important;

}
.departMentName{
  width:80%;
  white-space: pre-wrap;
}
</style>